import React,{useState} from "react"
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react"

const GMaps = (props) => {

  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});

  const onMarkerClick = (props,marker,e) => {
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const onMapClicked = (props) => {

    if(showingInfoWindow){
      setShowingInfoWindow(false)
      setActiveMarker(null)
    }
  }

  const mapWrapperStyle = {
    height: props.height ? props.height : "auto",
    position: "relative",
    ...props.style
  }



  return (
    <div style={mapWrapperStyle}>
      <Map
        google={props.google}
        zoom={props.zoom}
        initialCenter={{ lat: props.lat, lng: props.lng }}
        onClick={onMapClicked}
        styles={props.mapStyle}

      >
        <Marker onClick={onMarkerClick} icon={props.icon} position={{ lat: props.lat, lng: props.lng }}/>
        {
          props.markerText ?
            <InfoWindow marker={activeMarker}  visible={showingInfoWindow}>
              <p>
                {props.markerText}
              </p>
            </InfoWindow>
            : null
        }



      </Map>


    </div>


  )
}

GMaps.defaultProps = {
  mapStyle:[
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f1e9ea"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffb0b3"
        },
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "simplified"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        },
        {
          "weight": 0.5
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffabae"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ]
}
export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GMAP_API_KEY,
})(GMaps)
